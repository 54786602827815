@import '../stylekit';




.talking-about-us {
  h3 {
    font-size: 1rem;
    letter-spacing: 0;
    text-align: center;
    color: $sml-body-bg;
  }

  .talking-about-us-slider-element {
    background: linear-gradient(0deg, #3E0AA4 0%, #6016EF 100%);

    margin: 0 15px;
    height: 140px;
    border-radius: 8px;
  }
}
