@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../stylekit';
@import '../functions';

@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../stylekit';
@import '../functions';


.faq-box {
  background: $sml-white-gradient;
}

.premium-pricing {
  .premium-pricing-element {
    &.featured {
      background-image: $sml-white2-gradient;
      box-shadow: $sml-box-shadow;
    }

    .premium-pricing-element-button {
      &.isFree {
        color: $sml-body-color;

        .premium-pricing-element-button-inner {
          background-color: $sml-body-bg;
        }
      }
    }

    .premium-pricing-element-list-container {
      li {
        .coming-soon {
          background-color: rgba($sml-body-color, 0.2);
          color: $sml-body-color;
        }
      }
    }
  }
}

.premium-success-top-subtitle {
  font-size: 1.2rem;
}

.faq-box {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 30px;
  margin: 15px 0;

  .faq-box-answer {
    font-size: 1.2rem;
    opacity: 0.56;
  }
}

.premium-pricing {
  .premium-pricing-subtitle {
    font-size: 1.2rem;
  }

  .premium-pricing-switch {
    display: inline-flex;
    flex-direction: row;
    margin: auto;
    justify-content: center;
    position: relative;

    .fym-switch {
      margin: 0 5px;
    }
  }

  .premium-pricing-switch-label {
    margin: 0 5px;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 24px;

    opacity: 0.4;
    transition: opacity 0.3s;

    &.isActive {
      opacity: 1;
    }
  }

  .premium-pricing-save-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: -25%;
    top: -31px;
    opacity: 0.4;
    transition: opacity 0.3s;

    &.isActive {
      opacity: 1;
    }


    .premium-pricing-save-label {
      background-color: $sml-secondary;
      color: $sml-body-bg;
      font-size: 0.7rem;
      border-radius: 4px;
      transform: rotate(-15deg);
      transform-origin: bottom center;
      padding: 5px 10px;
    }

    .premium-pricing-save-oval {
      $oval-size: 12px;
      border-left: 2px solid $sml-secondary;
      border-bottom: 2px solid $sml-secondary;
      border-radius: 0 0 0 100%;
      height: $oval-size;
      width: $oval-size;
      margin: 4px 0 0 (($oval-size/2) + 10px);
    }

  }

  .premium-pricing-element {
    text-align: center;
    padding: 2rem;
    border-radius: 16px;

    .premium-pricing-element-label {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      opacity: 0;
      height: 55px;
      line-height: 55px;
      margin: -2rem -2rem 0;
      font-size: 1.2rem;
      font-weight: 500;
      text-transform: uppercase;
      background: $sml-white-gradient;


      color: $secondary;
      text-shadow: 0px 4px 4px rgba(51, 13, 24, 0.1);


      &.isVisible {
        opacity: 1;
      }
    }

    .premium-pricing-element-title {
      font-size: 1.5rem;
      font-weight: 500;
      padding: 1rem 0;
    }

    .premium-pricing-element-price {
      font-weight: bold;
      font-size: 2.3rem;

      .text-discounted {
        color: $sml-accent;
        font-size: 1.3rem;
        text-decoration: line-through;
      }
    }

    .premium-pricing-element-subtitle {
      font-size: 0.7rem;
      opacity: 0.56;
      font-weight: normal;
    }

    .premium-pricing-element-list-title {
      text-align: left;
      font-size: 0.7rem;
      opacity: 0.56;
      font-weight: bold;
    }

    .premium-pricing-element-list-container {
      list-style-type: none;
      padding-left: 0;
      margin-left: 1.7em;

      li {
        font-size: 1rem;
        font-weight: normal;
        text-align: left;
        padding: 0 0 20px;

        .coming-soon {
          padding: 2px 4px;
          text-transform: uppercase;
          border-radius: 4px;
          white-space: nowrap;
        }
      }
    }

    .premium-pricing-element-button {
      background: $sml-gradient;
      color: $sml-body-bg;
      border: 0;
      font-weight: bold;
      font-size: 1.2rem;
      margin: 2.6rem 0;
      padding: 0;

      .premium-pricing-element-button-inner {
        padding: 10px 0;
        border-radius: 0.3rem;
      }

      &.isFree .premium-pricing-element-button-inner {
        margin: 1px;
      }
    }
  }
}