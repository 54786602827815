@import '../stylekit';

.smartlinks-music-playlist {
  min-height: 1000px;
  margin-top: 200px;
  @include media-breakpoint-up("lg") {
    margin-top: 50px;
  }

  .box {
    box-shadow: $sml-box-shadow;
    border-radius: 16px;
    min-height: 201px;
  }
}

.bg-subpage-wave {
  position: absolute;
  left: 0;
  right: 0;
  top: -60%;
  bottom: -80%;
  z-index: -1;
  overflow: hidden;

  @include media-breakpoint-down('lg') {
    width: 100%;
    top: -55%;
  }

  img {
    height: 70%;
    width: 200%;

    @include media-breakpoint-up('lg') {
      width: 100%;
    }
  }
}
.top-promo-playlist-img-col {
  @include media-breakpoint-up('lg') {
    position: relative;
  }

  img {
    @extend .img-fluid;
    padding: 2rem 0;
    @include media-breakpoint-up('lg') {
      position: absolute;
      max-width: 165%;
      margin-left: -10%;
      margin-bottom: 30px;
      margin-top: 30px;
    }
    @include media-breakpoint-up('xl') {
      max-width: 150%;
    }
  }
}
