@import 'stylekit';
@import 'bootstrap';
@import 'promo-bar';
@import 'timer';
@import 'admin';
@import 'appleAd';
@import 'blog-cta-sml';
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../../../node_modules/slick-carousel/slick/slick';
@import '../../../node_modules/slick-carousel/slick/slick-theme';
@import 'animations';

html {
  font-size: 12px;
}

@media screen and (min-width: 320px) {
  html {
    font-size: calc(12px + 2 * ((100vw - 320px) / 680));
  }
}

@media screen and (min-width: 1000px) {
  html {
    font-size: 14px;
  }
}

#__next,
body,
html {
  height: 100%;
  width: 100%;
}

body {
  padding-top: 62px;
}

.mainNavbar {
  z-index: 3;
  background-color: $sml-body-bg;

  .nav-brand {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.main-header-subtitle {
  strong {
    background-color: $sml-body-color;
    color: $sml-body-bg;
  }
}

.dark-only {
  display: none;
}

.lead {
  color: mix($sml-body-color, $sml-body-bg, 56%);
}

.lang-picker {
  img {
    height: 12px;
    margin-right: 8px;
    margin-left: 8px;
    border: 1px solid $sml-body-color;
  }
}

a {
  text-decoration: none;
}


.lead {
  font-size: 1.5rem;
  font-weight: 300;
}

.main-header {
  font-size: 3rem;
  font-weight: bold;
}

.header-title {
  font-size: 3rem;
  @include media-breakpoint-up('lg') {
    font-size: 4.5rem;
  }
  font-weight: bold;
}

.navbar-toggle-custom {
  position: absolute;
  height: 57px;
}

.main-header-subtitle {
  opacity: 0.7;
  font-size: 1.3rem;

  @include media-breakpoint-up('md') {
    font-size: 1.5rem;
  }
}

.header-subtitle {
  color: rgba(249, 249, 249, 0.9);
  font-size: 1.3rem;

  @include media-breakpoint-up('md') {
    font-size: 1.5rem;
  }
}

.secondary-header {
  font-size: 2.5rem;
  @include media-breakpoint-up('md') {
    font-size: 3.5rem;
  }
  font-weight: normal;
  line-height: 1.2;
  overflow: hidden;
}

.smaller-header {
  font-weight: bold;
  font-size: 1.5rem;
  @include media-breakpoint-up('md') {
    font-size: 2.5rem;
  }
  //line-height: 1.2;
  overflow: hidden;
}


.app-detect {
  display: none;
}

.testimonial {
  font-size: 1.5rem;
  font-weight: 300;
}

.cookie-consent {
  z-index: 2001;
  position: fixed;
  left: 100px;
  right: 100px;
  bottom: 10px;
  @include media-breakpoint-down('md') {
    left: 15px;
    right: 15px;
  }
}

.music-service-logo {
  max-width: 100%;
  height: 60px;
  @include media-breakpoint-down('xs') {
    height: 30px;
  }
}


.coming-soon {
  background-color: rgba($sml-body-bg, 0.2);
  padding: 2px 4px;
  text-transform: uppercase;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 0.9rem;
}

.bg-waves {
  position: absolute;
  left: 0;
  right: 0;
  top: -80%;
  bottom: -80%;
  z-index: -1;
  overflow: hidden;

  img {
    height: 100%;
    width: 200%;

    @include media-breakpoint-up('lg') {
      width: 100%;
    }
  }
}


.text-gradient {
  display: inline-block;
  /* Fallback: Set a background color. */
  background-color: $sml-primary;

  /* Create the gradient. */
  background-image: $sml-gradient-text;

  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.rounded-top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.home-container, .sml-box-shadow {
  box-shadow: $sml-box-shadow;
}

@import 'home/downloadButtons';
@import 'home/top';
@import 'home/home';
@import 'home/downloads-info';
@import 'home/talking';
@import 'premium/pricing';
@import 'fym-button';
@import 'mobile-screenshots';
@import 'switch';
@import 'footer';
@import 'graphcms';
@import 'blog';
@import 'video';
@import 'animatedWave';
@import 'animatedTile';
@import 'smartLinksPage/description';
@import 'smartLinksPage/music-marketing';
@import 'smartLinksPage/musicIndustries';
@import 'smartLinksPage/smartLinks-stats';
@import 'smartLinksPage/artist-page';
@import 'smartLinksPage/release-page';
@import 'smartLinksPage/playlist-page';


