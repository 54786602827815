@import 'stylekit';
@import 'functions';
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';

.footer-waves {
  background: $sml-primary-dark;
  background-image: url('/statics/images/smartLinks/wave-bottom.svg');
  background-repeat: repeat-x;
  background-size: 200% 100%;

  @include media-breakpoint-up('lg') {
    background-size: 100% 100%;
  }
  min-height: 354px;
  width: 100%;
  margin-bottom: -100px;
}

.footer {
  hr {
    border-top-color: rgba($sml-body-color, 0.08);
  }
}

.footer-copyrights {
  opacity: 0.4;
  font-size: 0.8rem;
}

.social-icon {
  background: $sml-body-color;
  color: $sml-body-bg;
  opacity: 0.4;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  line-height: 24px;
  display: inline-block;
  font-size: 12px;
  text-align: center;
  margin: 0 10px;

  &:hover {
    color: $sml-body-color;
    background: $white;
    border: 1px solid $sml-body-color;
  }

  @include media-breakpoint-down('sm') {
    width: 36px;
    height: 36px;
    font-size: 1.3rem;
    line-height: 36px;
  }
}

.footer-links {
  white-space: nowrap;

  .footer-link {
    a {
      color: rgba($sml-body-color, 0.56);
    }

    font-size: 0.8rem;
  }
}

.footer-main-links {
  white-space: nowrap;

  .footer-main-link {
    a {
      color: $sml-body-color;
    }

    font-size: 1.5rem;
  }
}

.simple-footer-logo-container {
  &:hover {
    text-decoration: none;
  }
}
