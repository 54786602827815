@import 'functions';
@import 'stylekit';
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';

.graphcms-rich-content {
  p,
  ul,
  ol {
    opacity: 0.8;
  }

  img {
    height: auto;
    border-radius: 8px;
    display: block;

    max-width: 90%;
    margin: 20px auto;
    @include media-breakpoint-up('md') {
      max-width: 80%;
      margin: 50px auto;
    }
  }

  iframe {
    border-radius: 8px;
    display: block;

    max-width: 90%;
    margin: 20px auto;
    @include media-breakpoint-up('md') {
      max-width: 80%;
      margin: 50px auto;
    }
  }

  table {
    @extend .table;
    @extend .table-bordered;
    @extend .table-striped;
    @extend .table-hover;
    color: $sml-body-color !important;

    tbody tr:hover {
      color: $sml-body-color !important;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $fym-font-family-base;
    text-transform: uppercase;
    padding-top: 2rem;
    letter-spacing: 2px;
  }
}
