@use "sass:color";
@import 'stylekit';
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";


.btn:disabled.btn-input {
  color: $input-placeholder-color;
}


html {
  font-family: $font-family-base;
  //.svg-inline--fa {
  //  display: inline-block;
  //  height: 1em;
  //  overflow: visible;
  //  vertical-align: -.125em;
  //}
}

@import '../../../node_modules/bootstrap/scss/bootstrap';
@import '../../../node_modules/@fortawesome/fontawesome-svg-core/styles.css';

.btn.btn-secondary {
  @include button-variant($secondary, $secondary, $body-bg);
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($body-color, $body-bg, $value, color.change($value, $alpha: 0.2), $body-bg);
    border-color: color.change($value, $alpha: 0.2);
  }
}

.btn-outline-secondary {
  @include button-outline-variant($body-color, $body-bg, $secondary, $secondary);
}

@each $color, $value in $theme-colors {
  .btn-shadow-#{$color} {
    &:hover {
      transform: translateY(-4px);
    }

    transition: transform 0.3s;
    border: 0;
    box-shadow: 4px 4px 40px rgba(71, 8, 27, 0.2);
    background: linear-gradient(180deg, color.adjust($value, $whiteness: +15%, $blackness: -15%) 0%, $value 100%);
  }
}

.btn-xl {
  @include button-size('2rem', '2.5rem', $btn-font-size-lg, $btn-border-radius-lg);
}