@import '../stylekit';

.smartlinks-stats-element {
  .smartlinks-stats-element-label {
    font-weight: 500;
  }

  .smartlinks-stats-element-label-value {
    font-size: 14px;
  }
}

.image-stats {
  top: -100px;
  right: 0;
  z-index: 10;
}

.smartlinks-stats-square {
  width: 14px;
  height: 14px;
  border-radius: 5px;
}

