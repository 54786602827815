@import '../stylekit';



.smartlinks-feature-artist {
  .smartlinks-feature-artist-box {
    border-radius: 16px;
    font-size: 1.3rem;
    padding: 2.5rem;
    align-items: center;
    background: linear-gradient(180deg, rgba(242, 245, 255, 0.5) 0%, #F2F5FF 100%);


    text-align: left;
    height: 100%;
    display: flex;

    .svg-inline--fa {
      color: $primary-dark;
      margin-right: 1.2rem;
    }

    div {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.smartlinks-description-artist {
  background-color: $primary-dark;
  border-radius: 10px;
  color: $sml-body-bg;
  padding: 1rem;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.top-promo-artist-img-col {
  @include media-breakpoint-up('lg') {
    position: relative;
  }

  img {
    @extend .img-fluid;
    padding: 2rem 0;
    @include media-breakpoint-up('lg') {
      position: absolute;
      max-width: 165%;
      margin-left: -10%;
      margin-bottom: 30px;
      margin-top: 30px;
    }
    @include media-breakpoint-up('xl') {
      max-width: 150%;
    }
  }
}


