@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import './stylekit';
@import './functions';
.fym-button.force-light, .fym-button-force-light {
  &.outline {
    color: $sml-body-color;

    &:hover {
      color: $sml-body-bg;
    }

    .fym-button-bg {
      background-color: $sml-body-bg;
    }
  }
}

.fym-button.force-dark, .fym-button-force-dark {
  &.outline {
    color: $sml-body-bg;

    &:hover {
      color: $sml-body-bg;
    }

    .fym-button-bg {
      background-color: $sml-body-color;
    }
  }
}

.fym-button {
  @extend .fym-button-force-light;

  &.inverse {
    @extend .fym-button-force-dark;
  }
}

.fym-button {
  background: $sml-gradient;
  color: $sml-body-bg;
  position: relative;
  transition: color 0.2s ease-in-out;

  &.disabled {
    color: $sml-body-bg;
  }

  &:hover {
    color: $sml-body-bg;
    text-decoration: underline;

    .fym-button-bg {
      bottom: 100%;
    }
  }

  border: 0;
  font-weight: bold;
  font-size: 1.2rem;
  padding: 0;

  .fym-button-inner {
    position: relative;
    padding: 10px 20px;
    border-radius: 0.3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .fym-button-bg {
    position: absolute;
    left: 1px;
    top: 1px;
    bottom: 1px;
    right: 1px;
    border-radius: 0.26rem;
    transition: bottom 0.2s ease-in-out;
  }

  &.outline {
    padding: 1px;
    @include media-breakpoint-down('xs') {
      padding: 10px;
    }
  }

  @include media-breakpoint-down('xs') {
    padding: 10px;
  }
}
.fym-button-bg-smartlinks {
  background: linear-gradient(180deg, #FF568A 0%, #DE376A 100%);
  border-radius: 10px;
}

.fym-button-smartlinks-signup {
  background: $sml-primary;
}

.fym-button-smartlinks-login {
  background: $sml-body-bg;
  color: $sml-body-color;
  &:hover {
    color: $sml-body-color;
  }
}


.nav-item {
  .fym-button {
    .fym-button-inner {
      padding: 5px 20px;
      border-radius: 0.2rem;
      font-size: 1rem;
    }
  }
}
