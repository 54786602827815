@import '../stylekit';

.smartlinks-music-industries-image {
  filter: invert(1);
}

.smartlinks-music-industries {
  .smartlinks-music-industries-slider-element {
    background: $sml-white-gradient;

    img {
      filter: invert(1);
    }
  }
}

.smartlinks-music-industries {
  .smartlinks-music-industries-slider-element {
    margin: 10px 15px;
    padding: 0 30px;
    border-radius: 8px;
    height: 90px;
    font-weight: bold;
    font-size: 1.2rem;
  }
}
