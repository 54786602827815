@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../stylekit';

html.rtl {
  .home-links {
    text-align: right;
  }
}

.home-links {
  display: flex;
  text-align: left;
  flex-direction: column;

  $h: 60px;

  .home-links-link {
    border-bottom-color: rgba($sml-body-color, 0.12);

    a {
      color: $sml-body-color;
    }

    min-height: $h;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    display: flex;
    justify-content: center;
    align-items: center;

    a.text {
      flex: 1;
    }

    a {
      font-weight: 500;
    }
  }
}

.benefit-figures {
  top: 60px;
}

.benefit-figures-text {
  top: 10px;
}

.smartlink-bg-gradient {
  background: $sml-strong-gradient;
}

.smartlinks-page-explore {

  .smartlinks-page-explore-box {
    background: #6417FB;
    border-radius: 22px;
    position: relative;
    display: inline-block;
    overflow: hidden;
    text-align: center;

    &:hover {
      .smartlinks-page-explore-box-content {
        transform: translateY(-16px);
      }
    }

    .smartlinks-page-explore-box-content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      transition: transform 0.3s;

      p {
        color: $white;
        font-weight: bold;
        font-size: 1.8rem;
        position: absolute;
        bottom: 15%;
        left: 0;
        right: 0;
      }
    }
  }
}
