@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';

.top-points {
  font-size: 1.4rem;
  margin: 1em 0;
  @include media-breakpoint-up('md') {
    margin: 3em 0;
  }

  > div {
    opacity: 0.56;
    font-weight: normal;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    @include media-breakpoint-up('sm') {
      margin-left: 1em;
      margin-right: 1em;
    }
    @include media-breakpoint-up('lg') {
      margin-left: 1.5em;
      margin-right: 1.5em;
    }
    @include media-breakpoint-up('xl') {
      margin-left: 2em;
      margin-right: 2em;
    }
  }
}

.top-component-left {
  flex: 1;
  max-width: 700px;
}

.top-component-right {
  flex: 1;
  max-width: 800px;

  @include media-breakpoint-up('xl') {
    margin-left: 10em;
  }
}

.devices-example {
  display: flex;
  align-items: center;

  .devices-example-inner {

    position: relative;
    padding-left: 1em;
    padding-bottom: 3em;

    .device-phone {
      position: absolute;
      max-height: 100%;
      margin-left: -1em;
      top: 3em;
      bottom: 0;
      width: 100%;
      z-index: 1000;
    }
  }
}

.index-container {
  min-height: 100%;
}

.top-promo-img-col {
  @include media-breakpoint-up('lg') {
    position: relative;
  }

  .top-promo-img {
    @extend .img-fluid;
    padding: 2rem 0;
    @include media-breakpoint-up('lg') {
      z-index: 2;
      position: absolute;
      max-width: 165%;
      margin-bottom: 30px;
    }
  }
}
