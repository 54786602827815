@import '../stylekit';

.smartlinks-description-image {
  filter: invert(1);
}

.smartlinks-description {
  padding: 5rem 0;
  margin-bottom: 5rem;
  margin-top: 5rem;
  h3 {
    color: $sml-body-color;
  }

  .smartlinks-description-slider-element {
    background: $sml-white-gradient;

    img {
      filter: invert(1);
    }
  }

  .smartlinks-description-slider-element {
    margin: 10px 15px;
    padding: 0 30px;
    border-radius: 8px;
    height: 90px;
    font-weight: bold;
    font-size: 1.2rem;
  }

  .smartlinks-description-rectangle {
    width: 16px;
    height: 100%;
    margin-left: 0.8rem;
    margin-right: 0.8rem;

    @include media-breakpoint-up('lg') {
      width: 20px;
      margin-right: 1.5rem;
    }
    background: $secondary;
  }
}
.smartlinks-subpages-description {
  padding: 5rem 0;
  margin-bottom: 5rem;
  margin-top: 5rem;
  h3 {
    color: $sml-body-color;
  }
}

.bg-subpages-waves {
  position: absolute;
  left: 0;
  right: 0;
  top: -65%;
  bottom: -80%;
  z-index: -1;
  overflow: hidden;

  @include media-breakpoint-down('lg') {
    width: 100%;
    top: -55%;
  }

  img {
    height: 70%;
    width: 200%;

    @include media-breakpoint-up('lg') {
      width: 100%;
    }
  }
}
