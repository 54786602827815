@use "sass:color";
@import '../stylekit';


.smartlinks-floating-features {
  overflow: hidden;


  @include media-breakpoint-down('md') {
    .marketing-easy-phone {
      max-height: 350px;
    }
  }
}
.bg-sml-light {
  background: $body-bg-light;
}

.smartlinks-music-marketing {
  .audience {
    background: linear-gradient(90deg, #F7F2FF 59.13%, rgba(247, 242, 255, 0) 100%);
    padding: 2rem;
    margin-bottom: 20px;
    min-height: 180px;
    @include media-breakpoint-up("md") {
      border-radius: 24px;
    }
  }

  .audience-container {
    box-shadow: none;
    border-radius: 24px;
    padding: 0;
    @include media-breakpoint-up("md") {
      padding: 3rem;
      box-shadow: 4px 4px 40px rgba(148, 148, 148, 0.25);
    }
  }

  .smartlinks-music-marketing-box {
    box-shadow: $sml-box-shadow;
    border-radius: 16px;
    padding: 2.6rem;
    font-size: 1.3rem;
    text-align: left;
    align-items: start;
    height: 100%;

    display: flex;
    flex-direction: column;

    .svg-inline--fa {
      color: $primary-dark;
      margin-bottom: 1.2rem;
    }

    div {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.smartlinks-music-marketing-artist-home {
  .smartlinks-music-marketing-artist-element {
    .smartlinks-music-marketing-artist-element-label {
      text-transform: uppercase;
      font-weight: 300;
    }

    .smartlinks-music-marketing-artist-element-value {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 2rem;
      color: color.change($sml-secondary, $alpha: 0.7);
    }
  }
}

.smartlinks-music-marketing-artist {
  min-height: 1600px;
  margin-top: 300px;
  @include media-breakpoint-up("lg") {
    margin-top: 50px;
  }

  .box {
    box-shadow: $sml-box-shadow;
    border-radius: 16px;
    min-height: 201px;
  }
}

.rhomb-bottom-left {
  bottom: -10px;
  left: 25px;
}

.rhomb-top-left {
  top: -10px;
  left: 25px;
}

.rhomb-top-right {
  top: -10px;
  right: 25px;
}

.music-marketing-box {
  position: absolute;
  background: $white;
  border-radius: 12px;
  padding: 1.5rem;
  margin: 10px;
  display: flex;
  align-items: center;
  max-width: 250px;
  z-index: 100;
  box-shadow: 0 4px 40px rgba(172, 172, 172, 0.25);

  &.responsiveFeature {
    top: 53%;
    left: 10%;

    .rhomb {
      @extend .rhomb-top-left;
    }
  }

  &.customizeFeature {
    bottom: -24%;
    right: 10%;

    .rhomb {
      @extend .rhomb-top-right;
    }

    @include media-breakpoint-up('md') {
      top: 68%;
      right: 10%;
      bottom: unset;
    }
  }

  &.valuableTimeFeature {
    top: 16%;
    right: 6%;


    .rhomb {
      @extend .rhomb-top-right;
    }

    @include media-breakpoint-up('md') {
      top: 26%;
      right: 6%;
      bottom: unset;
    }
  }

  .rhomb {
    width: 24px;
    height: 24px;
    background: #DE376A;
    border-radius: 4px;
    transform: rotate(-45deg);
    position: absolute;
  }
}

.smartlinks-brand {
  padding-bottom: 3rem;
  padding-top: 3rem;
  margin-top: 3rem;
}

