@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';

.animated-wave {
  background-position: left center;
  background-image: url('/statics/images/download-waves-bg-light.svg');

  &.background-right {
    background-position: right center;

    .animated-wave-progress {
      background-position: right center;
      left: auto;
      right: 0;
    }
  }

  background-repeat: no-repeat;
  min-height: 142px;

  position: relative;
  max-width: 1262px;
  margin: auto;

  .animated-wave-progress {
    background-image: url('/statics/images/download-waves.svg');
    background-position: left center;
    background-repeat: no-repeat;
    clip-path: inset(0 0 0 0);
    min-height: 142px;
    transition: all 0.3s ease-out;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }

  .animated-wave-text {
    transition: opacity 1s;

    &.is-hidden {
      opacity: 0;
    }

    .animated-wave-text-title {
      opacity: 0.56;
      font-size: 1.5rem;

      .icon {
        margin-right: 16px;
      }
    }

    .animated-wave-text-number {
      font-size: 3rem;
      font-family: $font-family-monospace;
    }
  }
}
