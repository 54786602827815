@import '../stylekit';

.smartlinks-release-feature {
  min-height: 700px;
  margin-top: 400px;
  @include media-breakpoint-up("lg") {
    margin-top: 50px;
  }

  .box {
    background: rgba(100, 23, 251, 0.41);
    min-height: 156px;

    .icon {
      top: -40px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background-color: #480cbe;
    }
  }
}

.smartlinks-release {
  .circle-small {
    width: 81px;
    height: 81px;
    border-radius: 50%;
    background-color: #F2F5FF;
  }

  .circle-medium {
    width: 167px;
    height: 167px;
    border-radius: 50%;
    background-color: #F2F5FF;
  }

  .services {
    box-shadow: 8px 8px 30px rgba(31, 15, 63, 0.1);
    border-radius: 10px;
    transform: rotate(5.18deg);
    right: -20px;
  }
}

.smartlinks-release-marketing-box {
  background-color: rgba(100, 23, 251, 0.41);
  border-radius: 10px;
  font-size: 1.2rem;
  text-align: left;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;

  .svg-inline--fa {
    color: $sml-secondary;
    background-color: #490bc1;
    margin-bottom: 1.2rem;
    position: absolute;
    top: -20px;
    padding: 0.9rem;
    border-radius: 50%;
  }

  div {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 3rem;
    padding-bottom: 1rem;
    margin-top: 10px;
    text-align: center;
  }
}
.top-promo-release-img-col {
  @include media-breakpoint-up('lg') {
    position: relative;
  }

  img {
    @extend .img-fluid;
    padding: 2rem 0;

    max-width: 75%;
    @include media-breakpoint-up('lg') {
      position: absolute;
      max-width: 95%;
    }
  }
}
